import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
//import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Quote from "components/Typography/Quote.jsx";
import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";
// sections
import SectionCarousel from "./sectionCarouselTemplate";

class TemplateSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Work Documentation / Fleet Management:</h2>
              <h1>Automatic On-Site Views on Map</h1>
              <h2>Using Vehicle 360° Cameras</h2>
              <br/>
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "About",
                  tabIcon: "",
                  tabContent: (
                    <div>
                      <h2>Title</h2>
                      <SectionCarousel />
                      <h4>
                      I think that’s a responsibility that I have, to push
                      possibilities, to show people, this is the level that
                      things could be at. So when you get something that has
                      the name Kanye West on it, it’s supposed to be pushing
                      the furthest possibilities. I will be the leader of a
                      company that ends up being worth billions of dollars,
                      because I got the answers. I understand culture. I am
                      the nucleus.
                      </h4>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: "",
                  tabContent: (
                    <GridContainer>
                    <div className={classes.typo}>
                      <div className={classes.note}>Header 1</div>
                      <h1>The Life of Material Kit</h1>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>Header 1</div>
                      <h1 className={classes.title}>The Life of Material Kit</h1>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>Paragraph</div>
                      <p>
                        I will be the leader of a company that ends up being worth
                        billions of dollars, because I got the answers. I understand
                        culture. I am the nucleus. I think that’s a responsibility
                        that I have, to push possibilities, to show people, this is
                        the level that things could be at.
                      </p>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>Quote</div>
                      <Quote
                        text="I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at."
                        author=" Kanye West, Musician"
                      />
                    </div>
                  </GridContainer>
                  )
                },
                {
                  tabName: "Links",
                  tabIcon: "",
                  tabContent: (
                    <p className={classes.textCenter}>
                      I think that’s a responsibility that I have, to push
                      possibilities, to show people, this is the level that
                      things could be at. So when you get something that has
                      the name Kanye West on it, it’s supposed to be pushing
                      the furthest possibilities. I will be the leader of a
                      company that ends up being worth billions of dollars,
                      because I got the answers. I understand culture. I am
                      the nucleus.
                    </p>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(TemplateSection);
